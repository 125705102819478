.aboutUs{
    padding: 160px 10% 100px 10%;
}

@font-face {
    font-family: 'MontserratAlternates Bold';
    src: url('../fonts/MontserratAlternates-Bold.ttf');
}

.aboutUs-nav, .services-nav{
    display: flex;
}

.aboutUs-nav button, .services-nav button{
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'MontserratAlternates Bold';
    font-size: 15px;
    font-weight: 700;
    line-height: 21.48px;
    text-align: center;
    color: #F15A22B2;
    border: none;
    background-color: transparent;
    padding: 12px 23px;
    transition: .1s;
    cursor: pointer;
  }

.aboutUs-nav .active, .services-nav .active{
    color: #046381;
    background-color: #F15A2299;
    border-bottom: 5px solid #046381;
    border-radius:8px;
    padding: 12px 23px;
    box-shadow: 0px 4px 4px 0px #00000040 inset;
}

.active .icon-path{
    fill: #046381;
} 

.aboutUs-header{
    margin-top: 80px;
}

.aboutUs-content{
    display:flex;
    margin-bottom: 150px;
}

.aboutUs-img{
    width: 100%;
    margin-bottom: 18px;
}

.aboutUs-img img{
    width: 100%;
    border-radius: 32px;
}

.aboutUs-box{
    padding-left: 140px;
}

.aboutUs-box h3{
    font-family: 'MontserratAlternates Bold';
    color: #F15A22B2;
    font-size: 20px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    margin-bottom: 30px;
}
.aboutUs-box h2{
    color: #046381;
    font-family: "Montserrat Bold";
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 30px;
}

.aboutUs-txt{
    color: #000000B2;
    font-size: 14.5px;
    line-height: 37px;
    text-align: justify;
}

.clinic-result{
    margin-bottom: 180px;
}

.clinic-result h2, .why-youChoose-us h2, .our-partners h2, .qa-section-header h2{
    font-family: 'MontserratAlternates Bold';
    font-size: 26px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #046381;
    margin-bottom: 64px;
}

.why-youChoose-us-item p{
    font-size: 14.5px;
}

.clinic-result-box{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 16px; 
}

.clinic-result-item:nth-child(3) {
    grid-column: span 2;
}

.clinic-result-item{
    padding: 24px 25px;
    background-color: #EAFAFF;
    border-radius: 16px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    box-shadow: 0px 4px 4px 0px #00000040;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: url('../fonts/Montserrat-Bold.ttf');
}

.clinic-result-item h1{
    font-family: 'Montserrat Bold';
    font-size: 30px;
    line-height: 57.5px;
    letter-spacing: 0.2em;
    color: #F15A22;
}

.clinic-result-item p{
    color: #000;
    font-size: 16px;
    line-height: 19.5px;
    letter-spacing: -0.02em;
}

.why-youChoose-us{
    margin-bottom: 180px;
}

.why-youChoose-us-box{
    display: flex;
    justify-content: space-between;
}

.why-youChoose-us-item{
    width: calc(33.3% - 20px);
    padding: 30px;
    background-color: #EAFAFF;
    box-shadow: 0px 5px 9px 0px #00000040;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.why-youChoose-us-circle{
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
}

.why-youChoose-us-item h3{
    font-family: 'Montserrat Bold';
    font-size: 18px;
    line-height: 27.6px;
    letter-spacing: 0.1em;
    color: #046381;
    margin-bottom: 30px;
}

.why-youChoose-us-item {
    color: #000000B2;
    font-size: 18px;
    line-height: 37px;
    text-align: center;
}

.our-partners{
    margin-bottom: 180px;
}

.our-partners-list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-items: center; /* Центрируем элементы по горизонтали */
    row-gap: 50px;
}

.qa-section{
    padding-left: 20%;
    padding-right: 20%;
}

.qa-section-header{
    margin-bottom: 70px;
}

.qa-section-header h2{
    margin-bottom: 15px;
}

.qa-section-header p{
    font-size: 15px;
    line-height: 37px;
    text-align: center;
    color: #00000099;
}

#accordion{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.according-question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.according-question label{
    font-family: 'MontserratAlternates Bold';
    font-size: 17px;
    line-height: 27.6px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #046381;
    width: 80%;
}

.according-question svg{
    width: 32px;
    height: 32px;
}

.accordion-item{
    border: 2px solid #04638166;
    border-radius: 16px;
    padding: 24px;
    list-style: none;
    cursor: pointer;
}

.content{
    max-height: 0;
    overflow: hidden;
    transition: .4s ease-in-out;
}

.active .content{
    margin-top: 24px;
    padding-top: 24px;
    border-top: 2px solid #04638166;
    max-height: 300px;
}

.according-question svg{
    transition: .4s ease-in-out;
}

.active .according-question svg{
    transform: rotate(180deg);
}

.content p{
    font-size: 14.5px;
    line-height: 37px;
    text-align: left;
    color: #00000099;
}

/* Doctors list */

.medical-employees{
    display: flex;
    flex-direction: column;
    gap: 120px;
    padding-left: 8%;
    padding-right: 8%;
    margin-top: 60px;
}

.medical-employee-card{
    display: flex;
}

.medical-employee-img{
    width: 35%;
    height: 480px;
}

.medical-employee-content span{
    font-family: 'MontserratAlternates Bold';
    color: #F15A22B2;
    font-size: 16px;
}

.medical-employee-content h4{
    font-size: 16px;
    color: #000000B2;
    font-family: 'MontserratAlternates Bold';
    margin-bottom: 15px;
}

.medical-employee-content{
    width: 60%;
    padding: 0px 20px 64px 98px;
}

.medical-employee-content-reverse{
    padding-left: 0;
    padding-right: 98px;
}

.medical-employee-img-reverse{
    padding-right: 20px;
}

.medical-employee-img img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
}

.medical-employee-content h2, .medical-employee-content h3{
    font-family: 'MontserratAlternates Bold';
}

.medical-employee-content h2{
    font-size: 25px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #046381;
    margin-bottom: 14px;
}

.medical-employee-content h3{
    font-size: 16px;
    line-height: 27.6px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #000000B2;
    margin-bottom: 50px;
}

.medical-employee-content p{
    font-size: 15px;
    line-height: 37px;
    text-align: left;
    color: #000000B2;
}

/* Contact */

.contact-us{
    display: flex;
    margin-top: 70px;
}

.contact-title{
    width: 50%;
}

.contact-title h1{
    font-family: 'Montserrat Bold';
    font-size: 40px;
    line-height: 57.5px;
    letter-spacing: 0.2em;
    text-align: left;
    margin-bottom: 82px;
    color: #046381CC;
    padding-right: 80px;
}

.contact-title img{
    width: 45%;
}

.contact-box{
    width: 45%;
    padding: 50px 40px;
    background-color: #EAFAFF;
    box-shadow: 0px 5px 4px 0px #00000040;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-box-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.contact-box-header h2{
    font-family: 'MontserratAlternates Bold';
    font-size: 26px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #046381;
}

.contact-box-content{
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-bottom: 32px;
}

.contact-box-header svg{
    margin-bottom: 32px;
    width: 95px;
}

.contact-box-content h3>a{
    text-decoration: none;
    font-family: 'MontserratAlternates Bold';
    font-size: 16px;
    line-height: 27.6px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #00000099;
}

.contact-box-content h3{
    display: flex;
    align-items: center;
    gap: 18px;
    font-family: 'MontserratAlternates Bold';
    font-size: 16px;
    line-height: 27.6px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #00000099;
    padding-bottom: 16px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

.contact-box-content svg{
    width: 28px;
}


.contact-box-social{
    display: flex;
    align-items: center;
    gap: 32px;
}

.contact-box-social svg{
    width: 31px;
}

.our-partners-item svg{
    width: 210px;
}

@media (max-width:1520px) {
    .aboutUs {
        padding-left: 7%;
        padding-right: 7%;
    }
    .our-partners-list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .our-partners-item svg{
        width: 250px;
    }
}

@media (max-width:1250px) {
    .aboutUs-header{
        margin-top: 60px;
    }
    .medical-employee-img {
        width: 45%;
        height: 450px;
    }
    .medical-employee-content {
        width: 55%;
        padding: 0px 15px 50px 80px;
    }
    .medical-employee-content-reverse {
        padding-left: 0;
        padding-right: 98px;
    }

    .medical-employee-content h2 {
        font-size: 23px;
    }
    .medical-employee-content h3 {
        font-size: 15px
    }
    .medical-employee-content p {
        font-size: 15px;
    }
    .aboutUs {
        padding-left: 5%;
        padding-right: 5%;
    }
    .aboutUs-txt {
        font-size: 15px;
    }
    .aboutUs-box h2{
        font-size: 23px;
    }
    .aboutUs-box{
        padding-left: 90px;
    }
    .clinic-result-item h1{
        font-size: 27px;
    }
    .clinic-result-item p{
        font-size: 15px;
    }
    .our-partners-list{
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
    .our-partners-item svg{
        width: 210px;
    }
    .contact-title h1 {
        font-size: 40px;
    }
    .contact-box-content {
        gap: 30px;
    }
    .contact-box-header svg {
        width: 100px;
        margin-bottom: 25px;
    }
    .contact-box-header h2 {
        font-size: 26px;
    }
    .contact-box {
        padding: 52px 42px;
    }
    .medical-employee-content span {
        font-size: 15px;
    }
    .medical-employee-content h4{
        font-size: 15px;
    }
    .medical-employees{
        gap: 80px;
    }
}

@media (max-width:998px){
    .aboutUs{
        padding-top: 150px;
    }
    .active .content{
        max-height: 500px;
    }
    .contact-title img {
        display: none;
    }
    .contact-us {
        flex-direction: column;
        align-items: center;
    }
    .contact-title {
        width: 100%;
    }
    .contact-title h1 {
        text-align: center;
        font-size: 33px;
        padding: 0;
    }
    .contact-box {
        width: 90%;
    }
    .contact-box-header svg {
        width: 95px;
    }
    .contact-box-header h2 {
        font-size: 25px;
    }
    .contact-box-content h3 {
        gap: 15px;
        font-size: 17px;
    }
    .contact-box-social svg{
        width: 35px;
    }
    .contact-box-content h3>svg{
        width: 29px;
    }
    .aboutUs-nav .active {
        padding: 12px 18px;
    }
    .aboutUs-nav button {
        padding: 12px 18px;
        font-size: 16px;
    }
    .medical-employee-img {
        width: 45%;
        height: 490px;
    }
    .medical-employee-content {
        width: 55%;
        padding: 0px 0px 30px 70px;
    }
    .medical-employees {
        padding-left: 2%;
        padding-right: 2%;
    }
    .medical-employee-content h2 {
        font-size: 23px;
    }
    .medical-employee-content span {
        font-size: 15px;
    }
    .medical-employees {
        gap: 90px;
    }
    .medical-employee-content h3 {
        font-size: 15px;
        margin-bottom: 40px;
    }
    .medical-employee-content p {
        font-size: 15px;
    }
    .medical-employee-content-reverse {
        padding-left: 0;
        padding-right: 98px;
    }
    .why-youChoose-us-box {
        flex-direction: column;
        gap: 50px;
    }
    .why-youChoose-us-item {
        width: 90%;
        margin: 0 auto;
    }
    .aboutUs-box {
        width: 80%;
        padding-left: 0;
        margin: 0 auto;
    }
    .aboutUs-txt {
        font-size: 15px;
    }

    .aboutUs-content{
        flex-direction: column;
        gap: 50px;
    }
    .aboutUs-left{
        display: flex;
        flex-direction: column;
        gap: 50px;
    }
    .clinic-result-box{
        width: 80%;
        margin: 0 auto;
    }

    .aboutUs-img{
        width: 80%;
        margin: 0 auto;
    }

    .aboutUs-img img{
        width: 100%;
    }
    .aboutUs-img {
        width: 90%;
    }
    .clinic-result-box {
        flex-direction: column;
        gap: 30px;
    }

    .clinic-result-item {
        width: 100%;
        margin: 0 auto;
    }
    .our-partners-list{
        width: 90%;
        margin: 0 auto;
    }
    .our-partners-list{
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    }
    .our-partners-item svg{
        width: 195px;
    }
}

@media (max-width:768px){
    .aboutUs{
        padding-top: 140px;
    }
    .active .content{
        max-height: 600px;
    }
    .contact-title h1 {
        font-size: 26px;
    }
    .contact-box-header svg {
        width: 84px;
    }
    .contact-box {
        padding: 42px 35px;
    }
    .contact-box-header h2 {
        font-size: 24px;
    }
    .contact-box-content h3 {
        gap: 13px;
        font-size: 16px;
    }
    .contact-box-social svg {
        width: 32px;
    }
    .medical-employee-card {
        gap: 60px;
    }
    .medical-employees {
        padding-left: 0;
        padding-right: 0;
    }
    .medical-employee-img {
        width: 75%;
        height: 600px;
        padding-right: 0;
    }
    .medical-employee-content {
        width: 80%;
        padding: 0;
    }
    .aboutUs-txt {
        font-size: 14px;
    }
    .qa-section {
        padding-left: 15%;
        padding-right: 15%;
    }
    .accordion-item {
        padding: 18px;
    }
    .according-question label {
        font-size: 15px;
    }

    .according-question svg{
        width: 35px;
        height: 35px;
    }
    .our-partners-item svg{
        width: 180px;
    }
    .aboutUs-nav button{
        padding: 10px 18px;
        font-size: 15px;
    }

    .aboutUs-nav .active {
        padding: 10px 18px;
    }
    .clinic-result h2, .why-youChoose-us h2, .our-partners h2, .qa-section-header h2 {
        font-size: 26px;
    }
    .why-youChoose-us-item h3{
        font-size: 21px;
    }
    .why-youChoose-us-item p{
        font-size: 15px;
    }
    .why-youChoose-us-circle {
        width: 120px;
        height: 120px;
    }
    .clinic-result-item h4 {
        font-size:17px;
    }
    .clinic-result-item h1 {
        font-size:26px;
    }
    .aboutUs-left{
        gap: 30px;
    }
    .clinic-result-item p {
        font-size: 13px;
    }
    .medical-employee-card {
        flex-direction: column;
        align-items: center;
    }
    .medical-employee-card-reverse{
        flex-direction: column-reverse;
    }
    .contact-box {
        width: 95%;
    }
}

@media (max-width:576px){
    .aboutUs{
        padding-top: 125px;
    }
    .active .content{
        max-height: 700px;
    }
    .contact-title h1 {
        font-size: 20px;
        margin-bottom: 60px;
    }
    .contact-box-header svg {
        width: 75px;
        margin-bottom: 0;
    }
    .contact-box {
        padding: 22px 32px;
    }
    .contact-us {
        margin-top: 50px;
    }
    .contact-box-header h2 {
        font-size: 21px;
    }
    .contact-box-content h3 {
        gap: 14px;
        font-size: 13px;
    }
    .contact-box-social svg {
        width: 28px;
    }
    .contact-box-content h3>svg {
        width: 25px;
    }

    .medical-employee-img {
        width: 80%;
        height: 480px;
    }
    .medical-employee-content {
        width: 80%;
    }
    .medical-employee-card {
        gap: 40px;
    }
    .medical-employee-content h2 {
        font-size: 21px;
    }
    .medical-employee-content span{
        font-size: 14px;
    }
    .medical-employee-content h3{
        font-size: 14px;
    }
    .medical-employee-content h3 {
        font-size: 15px;
        margin-bottom: 35px;
    }
    .medical-employee-content p {
        font-size: 13px;
    }
    .aboutUs-nav button{
        padding: 8px 15px;
        font-size: 13px;
        white-space: nowrap;
    }

    .aboutUs-nav .active {
        padding: 8px 15px;
    }

    .aboutUs-nav svg{
        display: none;
    }
    .our-partners-list{
        row-gap: 55px;
    }
    .qa-section {
        padding-left: 10%;
        padding-right: 10%;
    }
    .according-question label {
        font-size: 14px;
    }
    .according-question svg {
        width: 28px;
        height: 28px;
    }
    .clinic-result h2, .why-youChoose-us h2, .our-partners h2, .qa-section-header h2 {
        font-size: 22px;
    }
    .aboutUs-box{
        width: 90%;
    }
    .aboutUs-box h2 {
        font-size: 20px;
    }

    .aboutUs-box h2::before {
        width: 50px;
        height: 3px;
    }

    .aboutUs-header{
        margin-bottom: 80px;
    }

    .qa-section-header p {
        font-size: 13px;
    }
    .our-partners, .clinic-result {
        margin-bottom: 120px;
    }
    .our-partners-item svg {
        width: 155px;
    }
    .aboutUs-txt {
        font-size: 13px;
    }
    .content p {
        font-size: 13px;
    }
    .why-youChoose-us-item h3{
        font-size: 18px;
    }
    .why-youChoose-us-item p{
        font-size: 14px;
    }
    .why-youChoose-us-circle {
        width: 110px;
        height: 110px;
    }
    .why-youChoose-us-item {
        padding: 25px;
    }
    .clinic-result-item h4 {
        font-size:16px;
    }
    .clinic-result-item h1 {
        font-size:25px;
    }
    .clinic-result-item p {
        font-size: 12px;
    }
    .clinic-result-box{
        width: 90%;
    }
    .clinic-result-box{
        gap: 20px;
    }
    .contact-box-content h3>a{
        font-size: 13px;
    }
    .contact-box {
        width: 100%;
    }
}

@media (max-width:450px){
    .medical-employee-img {
        width: 87%;
        height: 480px;
    }
    .medical-employee-content {
        width: 90%;
    }
    .clinic-result-item{
        padding: 15px 15px;
    }
    .clinic-result-item h1{
        line-height: 35px;
        font-size: 23px;
    }
    .clinic-result-box{
        gap: 15px;
    }
}