/* Footer */
.footer{
    padding: 96px 10%;
    background-color: #fff;
    border-top: 3px solid #046381;
}

.footer-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 2px solid #046381;
}

.footer-icons{
    display: flex;
    justify-content: space-between;
}

.footer-icons img{
    width: 28px;
    height: 28px;
}

.footer-company-name{
    width: 12%;
}

.footer-icons{
    width: 16%;
}

.footer-logo{
    width: 18%;
}

.footer-logo img{
    width: 100%;
}

.footer-company-name p{
    font-family: "MontserratAlternates Medium";
    font-size: 15px;
    line-height: 37px;
    color: #046381;
}

.footer-content{
    font-size: 14px;
    font-weight: 500;
    line-height: 19.09px;
    letter-spacing: -0.02em;
    text-align: left;
    display: flex;
    justify-content: left;
    gap: 100px;
}

.footer-item{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.footer-item a{
    text-decoration: none;
    color: #046381;
}

@media (max-width:1250px) {
    .footer-icons img{
        width: 26px;
        height: 26px;
    }
    .footer-company-name {
        width: 15%;
    }   
    .footer-icons{
        width: 20%;
    }

    .footer-company-name{
        text-align: center;
    }
    .footer-company-name p {
        font-size: 14px;
    }
}

@media (max-width:998px){
    .footer-company-name {
        width: 19%;
    } 
    .footer-icons{
        width: 22%;   
    }

    .footer-icons img{
        width: 24px;
        height: 24px;
    }
    .footer-logo{
        width: 22%;
    }
}

@media (max-width:768px) {
    .footer-icons img{
        width: 23px;
        height: 23px;
    }
    
    .footer-company-name{
        width: 25%;
    } 

    .footer-company-name p {
        font-size: 13px;
    }
    
    .footer-icons {
        width: 25%;
    } 
    
    .footer-logo{
        width: 27%;
    }
}

@media (max-width: 576px) { 
    .footer-icons img{
        width: 25px;
        height: 25px;
    }
    .footer-company-name p {
        font-size: 13px;
    }
    .footer-icons {
        width: 60%;
    } 
    .footer-logo{
        width: 50%;
    }
    .footer-company-name{
        width: 50%;
    }
    .footer-header{
        flex-direction: column;
        gap: 30px;
    }
}

@media (max-width: 460px) { 
    .footer-icons img{
        width: 24px;
        height: 24px;
    }
    .footer-content{
        font-size: 13px;
    }
}
