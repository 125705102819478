.services-container, .capelnisya-container{
    padding: 160px 10%;
}

.services-grid, .capelnisya-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-auto-rows: minmax(300px, auto); 
    margin-top: 76px;
    row-gap: 32px;
    column-gap: 21px;
}

.capelnisya-grid{
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.capelnisya-grid{
    margin-top: 130px;
    row-gap: 111px;
}

.capelnisya-grid-card, .services-grid-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    box-shadow: 0px 5px 9px 0px #00000026;
    border-radius: 16px;
    text-decoration: none;
    color: #000;
}

.capelnisya-grid-card{
    position: relative;
}

.services-grid-img{
    height: 232px;
}

.capelnisya-grid-img{
    width: 100%;
    height: 183px;
}

.capelnisya-grid-img{
    background-color: #F3A3674D;
    border-radius: 16px 16px 0 0;
}

.services-grid-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 16px 16px 0 0;
}

.services-grid-content, .capelnisya-grid-content{
    flex-grow: 1;
    padding: 15px 25px;
    background-color: #EAFAFF;
    border-radius: 0 0 16px 16px;
}

@font-face {
    font-family: "MontserratAlternates Bold";
    src: url('../fonts/MontserratAlternates-Bold.ttf');
}

.services-grid-content h4, .capelnisya-grid-content h4{
    font-family: "MontserratAlternates Bold";
    font-size: 16px;
    line-height: 20.7px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #046381;
    margin-bottom: 12px;
}

.services-grid-content>p, .price-box>p, .capelnisya-grid-content>p{
    font-size: 14px;
    line-height: 19.5px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 12px;
}

.price-box>p{
    margin-bottom: 5px;
}

.price-box>h3{
    font-family: "MontserratAlternates Bold";
    font-size: 17px;
    line-height: 30.96px;
    letter-spacing: 0.1em;
    text-align: left;
}

.capelnisya-3d-img{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -35%);
    width: 180px;
    height: 210px;
    border-radius:24px;
}

@media (max-width:1520px) {
    .services-container, .capelnisya-container{
        padding-left: 7%;
        padding-right: 7%;
    }
}

@media (max-width:1250px){
    .services-container, .capelnisya-container{
        padding-left: 5%;
        padding-right: 5%;
    }
    .services-grid, .capelnisya-grid{
        grid-template-columns: repeat(3, 1fr);
        row-gap: 38px;
    }
    .capelnisya-grid{
        row-gap: 120px;   
    }
    .services-grid-img{
        height: 250px;
    }
}

@media (max-width:998px) {
    .services-grid, .capelnisya-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .services-grid-img {
        height: 275px;
    }
}

@media (max-width:768px) {
    .services-nav button{
        padding: 13px 25px;
    }

    .services-nav .active {
        padding: 13px 25px;
    }
    .services-container, .capelnisya-container{
        padding-top: 130px;
        padding-bottom: 130px;
    }
    .nav-btn {
        font-size: 12px;
    }
    .services-grid-img {
        height: 210px;
    }
}

@media (max-width: 576px) { 
    .services-grid, .capelnisya-grid{
        grid-template-columns: repeat(1, .9fr);
        justify-content: center;
        margin-top: 55px;
    }
    .capelnisya-grid{
        margin-top: 110px;
    }
    .services-nav button{
        padding: 8px 15px;
        font-size: 13px;
        white-space: nowrap;
    }
    .services-nav{
        flex-wrap: wrap;
        row-gap: 15px;
    }

    .services-nav .active {
        padding: 8px 15px;
    }
    .services-container, .capelnisya-container{
        padding-top: 115px;
        padding-bottom: 115px;
    }
    .services-grid-img {
        height: 310px;
    }
}

@media(max-width:460px){
    .services-grid-img {
        height: 215px;
    }
}