.detail-page, .detail-capelnisya{
    padding: 160px 10%;
}

.detail-header{
    display: flex;
    margin-bottom: 100px;
}

.detail-img, .detail-capelnisya-img{
    width: 47%;
    height: 408px;
    border-radius: 24px;
}

.detail-capelnisya-img{
    display: flex;
    align-items: center;
    justify-content: center;
}
.detail-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
}

.detail-capelnisya-img img{
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
}

.detail-content{
    width: 50%;
    padding-top: 47px;
    padding-left: 122px;
} 

.detail-content-title{
    margin-bottom: 25px;
}

.detail-content-title p, .detail-content h2, .detail-content h4{
    font-family: 'MontserratAlternates Bold';
} 

.detail-content h2{
    font-size: 26px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #046381E5;
    margin-bottom: 15px;
}

.detail-content-title p{
    font-size: 16px;
    line-height: 20.7px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #000000A6;
}

.detail-content h4{
    color: #000000A6;
    font-size: 14px;
    line-height: 20.7px;
    letter-spacing: 0.1em;
    text-align: left;
    margin-bottom: 8px;
}

.detail-service-quickAccess h4, .detail-capelnisya-quickAccess h4{
    margin-bottom: 16px;
}

.quick-access-btn{
    display: inline-block;
    font-family: "MontserratAlternates Bold";
    padding: 16px 20px;
    text-decoration: none;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    background-color: transparent;
    color: #fff;
    background-color: #046381;
}

.detail-description-content{
    margin-bottom: 60px;
}

.detail-description-content h2{
    font-family: "MontserratAlternates Bold";
    font-size: 26px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #046381;
    margin-bottom: 24px;
}

.detail-description-content p{
    font-size: 15.5px;
    line-height: 37px;
    text-align: left;
}

.detail-description-content h2::before {
    content: '';
    display: inline-block; /* или 'block', в зависимости от вашего макета */
    width: 50px;
    height: 4px;
    border-radius: 25px;
    background-color: #046381;
    margin-right: 15px; /* добавьте отступ, если нужно, чтобы псевдоэлемент не прилегал к тексту */
    vertical-align: middle; /* чтобы выровнять псевдоэлемент по центру текста */
}

.detail-bottom-quickAccess h3{
    font-family: 'MontserratAlternates Bold';
    font-size: 16.5px;
    line-height: 30.96px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #000000A6;
    margin-bottom: 24px;
}

.detail-bottom-quickAccess .quick-access-btn{
    background-color:transparent;
    color: #046381;
    border: 2px solid #046381;
}

.btn-back{
    font-family: 'MontserratAlternates Bold';
    text-decoration: none;
    color: #046381;
    font-size: 20px;
    margin-bottom: 50px;
    display: block;
    transition: .4s;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.btn-back:hover{
    color: #046381CC;
}

@media (max-width:1520px) {
    .detail-page{
        padding-left: 7%;
        padding-right: 7%;
    }
}

@media (max-width:1250px) {
    .detail-page{
        padding-left: 5%;
        padding-right: 5%;
    }
    .detail-content h2 {
        font-size: 27px;
    }
    .detail-content-title p {
        font-size: 17px;
    }
    .detail-img, .detail-capelnisya-img {
        width: 45%;
    }
    .detail-description-content h2 {
        font-size: 27px;
    }
    .detail-description-content p {
        font-size: 15px;
    }
    .detail-bottom-quickAccess h3 {
        font-size: 16px;
    }
}

@media (max-width:998px){
    .btn-back{
        font-size: 18px;
    }
    .detail-page{
        padding-left: 7%;
        padding-right: 7%;
        padding-top: 145px;
        padding-bottom: 145px;
    }

    .detail-header {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .detail-img, .detail-capelnisya-img {
        width: 95%;
    }
    .detail-content {
        width: 95%;
        padding: 0;
        padding-left: 30px;
    }
    .detail-description, .detail-bottom-quickAccess{
        width: 95%;
        margin: 0 auto;
    }
    .detail-description-content h2::before {
        width: 55px;
    }
}

@media (max-width:768px){
    .btn-back{
        font-size: 17px;
    }
    .detail-description-content h2::before {
        width: 50px;
    }
    .detail-img, .detail-capelnisya-img {
        width: 90%;
    }
    .detail-content h2 {
        font-size: 24px;
    }
    .detail-description-content h2 {
        font-size: 24px;
    }
    .detail-content {
        width: 90%;
    }
    .detail-description, .detail-bottom-quickAccess{
        width: 90%;
    }
    .detail-page{
        padding-top: 130px;
        padding-bottom: 130px;
    }

}

@media (max-width:576px){
    .detail-page{
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .btn-back{
        font-size: 16px;
    }
    .detail-img, .detail-capelnisya-img {
        width: 95%;
        height: 300px;
    }
    .detail-content {
        width: 95%;
        padding-left: 25px;
    }
    .detail-content h2 {
        font-size: 21px;
    }
    .detail-content-title p {
        font-size: 15px;
    }
    .detail-description-content h2 {
        font-size: 21px;
    }
    .detail-description-content h2::before {
        width: 40px;
    }
    .detail-description-content p {
        font-size: 14px;
    }
    .quick-access-btn {
        padding: 14px 18px;
        font-size: 14px;
    }
    .detail-service-quickAccess h4, .detail-capelnisya-quickAccess h4 {
        margin-bottom: 24px;
    }
    .detail-description-content {
        margin-bottom: 65px;
    }
}