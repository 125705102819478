@font-face {
    font-family: "Montserrat SemiBold";
    src: url('../fonts//MontserratAlternates-SemiBold.ttf');
}

.navbar{
    padding: 0px 10%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom:1.5px solid #046381;
}

.nav-logo{
    width: 170px;
    height: 100px;
}

.nav-logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nav-btns{
    display: flex;
    justify-content: center;
    width: 70%;
    gap: 40px;
    align-items: center;
}

.nav-btn, .switch-language{
    font-family: "Montserrat SemiBold";
}

.nav-btn{
    text-decoration: none;
    font-size: 14px;
    line-height: 19.5px;
    color: #046381;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: .4s ease;
}

.nav-btn:hover{
    color: #F15A22;    
}

.btn-reserve>.nav-btn{
    color: #fff;
    background-color: #046381;
    padding: 13px 14px;
    border-radius: 8px;
    transition: .4s ease;
}

.btn-reserve>.nav-btn:hover{
    background-color:#F15A22B2;
}

.switch-language{
    /* color: #046381; */
    display: flex;
    align-items: center;
    gap: 10px;
    transition: .4s ease;
    cursor: pointer;
}

.switch-language #svg-switch path {
    transition:0.4s ease;
}

.switch-language:hover #svg-switch path {
    fill: #F15A22B2;
    fill-opacity: 1;
}

.languages{
    font-family: "Montserrat SemiBold";
    background-color: transparent;
    border: none;
    outline: none;
    -webkit-appearance: none; /* Убирает стрелку в Chrome, Safari, Opera */
    -moz-appearance: none; /* Убирает стрелку в Firefox */
    appearance: none;
    color: #046381;
    font-size: 14px;
    line-height: 19.5px;
    text-align: left;
    transition: .4s ease;
    cursor: pointer;
}

.switch-language:hover .languages{
    color: #F15A22B2;
}

.languages option{
    color: #000;
    border: none;
}

.nav-burger{
    display: none;
}

.burger-btn{
    background-color: transparent;
    border: none;
    color: #046381;
    font-size: 20px;
    transition: .4s ease;
    cursor: pointer;
    margin-top: 5px;
}

.burger-btn:hover{
    color: #F15A22B2;
}

.nav-burger-btns{
    display: none;
}

@media (max-width:1520px) {
    .navbar{
        padding: 0px 7%;
    }
    .nav-btns {
        gap: 30px;
        justify-content: space-between;
        width: 72%;
    }
    .nav-btn {
        font-size: 15px;
    }
}

@media (max-width:1250px){
    .navbar{
        padding: 0px 5%;
    }
    .nav-btns {
        gap: 20px;
        justify-content: space-between;
    }
    .nav-btn {
        font-size: 13px;
    }
    .btn-reserve>.nav-btn {
        padding: 14px;
    }
    .nav-logo {
        width: 155px;
        height: 90px;
    }
    .switch-language {
        gap: 6px;
    }
}

@media (max-width:998px) {
    .navbar{
        padding: 0px 7%;
    }
    .nav-btns{
        display: none;
    }
    
    .nav-burger{
        width: 65%;
        display: flex;
        justify-content: flex-end;
        gap: 30px;
        position: relative;
    }
    
    .responsive_nav{
        display: flex;
    }

    .nav-burger-btns{
        position: absolute;
        flex-direction: column;
        gap: 20px;
        top: 195%;
        background-color: #eafaff;
        padding: 20px;
    }

    .nav-logo {
        width: 160px;
        height: 90px;
    }
}

@media (max-width:768px) {
    .nav-burger {
        width: 56%;
    }
    .nav-logo {
        width: 140px;
        height: 80px;
    }
    .languages{
        font-size: 14px;
    }
    .nav-burger-btns{
        top: 180%;
    }
}

@media (max-width: 576px) { 
    .nav-burger {
        width: 50%;
    }
    .nav-logo {
        width: 125px;
        height: 75px;
    }
    .nav-burger-btns{
        top: 172%;
        width: 180px;
    }
}

@media (max-width: 460px) { 
    .nav-burger {
        width: 40%;
    }
    .languages {
        font-size: 13px;
    }
}