.reserve-clinic{
    padding-top: 200px;
    padding-bottom: 100px;
    background-image: url('../images/reserve-background.jfif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}

.form-container{
    width: 27%;
    padding: 60px 50px 70px 50px;
    background-color: #fff;
    border-radius: 24px;
    box-sizing: border-box;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
}

.form-item{
    width: 100%;
}

.form-item input{
    font-size: 13px;
    width: 100%;
    padding: 13px 21px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 2px solid #00000030;
}

.form-container h4{
    color: #046381B2;
    font-family: "MontserratAlternates Bold";
    font-size: 16px;
    line-height: 20.7px;
    letter-spacing: 10%;
    margin-bottom: 45px;
}

.form button{
    font-family: "MontserratAlternates Bold";
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 8px;
    background-color: #046381;
    border: none;
    color: #fff;
    font-size: 15px;
    line-height: 19.5px;
    cursor: pointer;
    transition: .4s ease;
}

.form button:hover{
    background-color: #046381B2;
}

@media (max-width:1250px){
    .form-container {
        width: 33%;
    }
}

@media (max-width:998px){
    .form-container {
        width: 40%;
        padding: 40px;
    }

    .form-item input {
        padding: 11px 19px;
        font-size: 12.5px;
    }
    .form button {
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

@media (max-width:768px) {
    .form-container h4 {
        font-size: 15px;
        margin-bottom: 38px;
    }
    .form-container {
        width: 47%;
        padding-left: 35px;
        padding-right: 35px;
    }
    .form button {
        font-size: 13px;
    }
    .form-item input {
        font-size: 12px;
    }
}

@media (max-width: 576px) { 
    .form-container {
        width: 58%;
        padding-top: 35px;
        padding-left: 32px;
        padding-right: 32px;
    }
    .form-item input {
        padding: 10px 16px;
        font-size: 11px;
    }
    .form button {
        font-size: 12px;
        padding-top: 11px;
        padding-bottom: 11px;
    }
    .form-container h4 {
        font-size: 14px;
    }
    .form {
        gap: 16px;
    }
}

@media (max-width: 400px) { 
    .form-container {
        width: 69%;
    }

    .form button {
        font-size: 11px;
    }
}