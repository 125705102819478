.confirmation{
    padding: 150px 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation h2{
    font-family: 'MontserratAlternates Bold';
    font-size: 29px;
    line-height: 39.1px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #046381;
    margin-top: 15px;
    margin-bottom: 15px;
}

.confirmation p{
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    color: #00000099;
    margin-bottom: 45px;
}

.confirmation img{
    width: 60%;
}

.confirmation-btn{
    font-family: 'MontserratAlternates Bold';
    text-decoration: none;
    padding: 16px 20px;
    background-color: #046381;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;
    line-height: 19.5px;
    transition: .4s ease;
}

.confirmation-btn:hover{
    background-color:#046381B2;
}

@media (max-width:1520px){
    .confirmation {
        padding: 150px 25%;
    }
    .confirmation img {
        width: 50%;
    }
    .confirmation p {
        font-size: 17px;
    }
    .confirmation h2 {
        font-size: 30px;
    }
}

@media (max-width:1250px){
    .confirmation {
        padding: 130px 23%;
    }
    .confirmation h2 {
        font-size: 28px;
    }
    .confirmation p {
        font-size: 16px;
    }
    .confirmation-btn {
        padding: 15px 20px;
        font-size: 15px;
    }
}

@media (max-width:998px){
    .confirmation {
        padding: 150px 20%;
    }
    .confirmation img {
        width: 65%;
    }
    .confirmation h2 {
        font-size: 26px;
    }
    .confirmation p {
        font-size: 15px;
    }
    .confirmation-btn {
        padding: 15px 19px;
        font-size: 14px;
    }
}

@media (max-width:768px){
    .confirmation {
        padding: 150px 18%;
        padding-bottom: 100px;
    }
    .confirmation img {
        width: 75%;
    }
    .confirmation h2 {
        font-size: 24px;
    }
    .confirmation p {
        font-size: 15px;
    }
    .confirmation-btn {
        padding: 15px 18px;
        font-size: 13px;
    }
}   

@media (max-width:576px){
    .confirmation {
        padding: 130px 11%;
        padding-bottom: 100px;
    }
    .confirmation img {
        width: 95%;
    }
    .confirmation h2 {
        font-size: 21px;
    }
    .confirmation p {
        font-size: 14px;
    }
    .confirmation-btn {
        padding: 14px 18px;
        font-size: 13px;
    }
}