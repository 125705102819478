.main{
    padding: 170px 10% 100px 10%;
}

.main-header{
    display: flex;
}

.clinic-description{
    width: 50%;
    padding-top: 42.5px;
    padding-bottom: 42.5px;
    padding-right: 83px;
}

.clinic-header-picture{
    width: 50%;
}

.main-header{
    margin-bottom: 140px;
}

@font-face {
    font-family: "Montserrat Bold";
    src: url('../fonts/Montserrat-Bold.ttf');
}

.header-title{
    font-family: "Montserrat Bold";
    color: #046381;
    font-size: 32px;
    font-weight: 700;
    line-height: 57.5px;
    letter-spacing: 0.2em;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.clinic-description p{
    font-size: 15px;
    line-height: 37px;
    color: #000000B2;
    margin-bottom: 35px;
}

.header-btns{
    display: flex;
    gap: 18px;
    white-space: nowrap;
}

@font-face {
    font-family: "MontserratAlternates Bold";
    src: url('../fonts/MontserratAlternates-Bold.ttf');
}

.header-btn{
    font-family: "MontserratAlternates Bold";
    padding: 16px 20px;
    text-decoration: none;
    border-radius: 8px;
    border: none;
    font-size: 14px;
    background-color: transparent;
}

.header-btn:first-child{
    color: #fff;
    background-color: #046381;
    transition: .4s ease;
}

.header-btn:last-child{
    border: 2px solid #046381;
    color: #046381;
}

.picture-container{
    width: 100%;
    position: relative;
}

.backround-ramka{
    position: absolute;
    border-radius: 190px;
    border: 3px solid #046381;
    width: 90%;
    height: 97%;
    top: 1%;
    right: -15px;  
    z-index: -2;  
}

.name-medicine{
    position: absolute;
    top: 15%;
    right: -12rem;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #046381;
    color: #fff;
    padding: 10px 10px;
    border-radius: 25px;
    font-size: 15px;
    font-family: "MontserratAlternates Bold";
}

.name-medicine img{
    width: 35px;
    height: 35px;
}

.picture-container>img{
    width: 100%;
    max-height: 100%;
    border-radius: 24px;
}

.popular-services{
    margin-bottom: 180px;
    color: #046381;
}

.popular-service-list{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    align-items: stretch; 
}

.popular-services-item{
    width: calc(33.3% - 20px);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}

.popular-services h2, .popular-capelnisya h2{
    font-family: "MontserratAlternates Bold";
    font-size: 24px;
    line-height: 39.1px;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 96px;
}

.popular-service-img{
    background-color: #F3A3674D;
    height: 270px;
    border-radius:24px 24px 0 0;
    object-fit: cover;
}

.popular-capelnisya-img{
    background-color: #F3A3674D;
    height: 230px;
    border-radius:24px 24px 0 0;
}

.popular-service-content{
    padding: 30px 18px;
    text-align: center;
    background-color: #EAFAFF;
    border-radius: 0 0 24px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.popular-service-content h3{
    font-family: "MontserratAlternates Bold";
    font-size: 18px;
    line-height: 27.6px;
    letter-spacing: 10%;   
    margin-bottom: 16px;
}

.popular-service-content p{
    color: #000000B2;
    font-size: 15px;
    line-height: 37px;
    margin-bottom: 16px;
}

.popular-service-btn{
    display: inline-block;
    font-family: "MontserratAlternates Bold";
    padding: 10px 15px;
    background-color: transparent;
    border: 2px solid #046381;
    border-radius: 8px;
    font-size: 15px;
    line-height: 29.26px;
    cursor: pointer;
    color: #046381;
    text-decoration: none;
    transition: .4s ease;
    width: fit-content;
    align-self: center;
}

.popular-capelnisya{
    margin-bottom: 0;
    color: #046381;
}

.show-services-btn{
    display: flex;
    justify-content: center;
}

.show-services-btn .btn-services{
    font-family: "MontserratAlternates Bold";
    padding: 13px 15px;
    border-radius: 8px;
    background-color: #046381;
    color: #fff;
    font-size: 15.5px;
    line-height: 29.26px;
    border: none;
    cursor:pointer;
    text-decoration: none;
    transition: .4s ease;
}

.popular-capelnisya h2{
    margin-bottom: 180px;
}

.service-3d-img{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -35%);
    width: 220px;
    height: 240px;
    border-radius:24px;
}

.header-btn:first-child:hover, .btn-services:hover{
    background-color: #046381B2;
}

.header-btn:last-child:hover, .popular-service-btn:hover{
    background-color: #046381B2;
    border-color: #046381B2;
    color: #fff;
}

@media (max-width:1520px) {
    .main{
        padding-left: 7%;
        padding-right: 7%;
    }
    .popular-services-item{
        width: calc(33.3% - 30px);
    }
    .header-title {
        font-size: 42px;
    }
    .clinic-description p {
        font-size: 17px;
    }
    .popular-service-content h3 {
        font-size: 20px;
    }
    .popular-service-content p {
        font-size: 17px;
    }
    .popular-service-btn {
        font-size: 19px;
    }
    .clinic-header-picture {
        width: 48%;
    }
    .service-3d-img {
        width: 210px;
        height: 225px;
    }
    .popular-capelnisya-img {
        height: 220px;
    }
    .popular-services {
        margin-bottom: 160px;
    }
}

@media (max-width:1250px){
    .main{
        padding-left: 5%;
        padding-right: 5%;
    }
    .header-title {
        font-size: 35px;
    }
    .clinic-description p {
        font-size: 16px;
    }
    .header-btn {
        padding: 15px 18px;
        font-size: 15px;
    }
    .clinic-description {
        width: 53%;
        padding-right: 60px;
    }
    .clinic-header-picture {
        width: 47%;
    }
    .popular-service-img {
        height: 245px;
    }
    .popular-service-content h3 {
        font-size: 20px;
    }
    .popular-service-content p {
        font-size: 16px;
    }
    .popular-service-btn {
        font-size: 16.5px;
    }
    .popular-services h2, .popular-capelnisya h2 {
        font-size: 28px;
    }
    .service-3d-img {
        width: 180px;
        height: 210px;
    }
    .popular-capelnisya-img {
        height: 205px;
    }
    .main-header {
        margin-bottom: 140px;
    }
    .show-services-btn .btn-services {
        padding: 16px 15px;
        font-size: 16px;
    }
}

@media (max-width:998px) {
    .main{
        padding-top: 150px;
    }
    .popular-service-list {
        flex-direction: column;
        gap: 80px;
    }
    .popular-capelnisya>.popular-service-list{
        gap: 160px;
    }
    .popular-services-item {
        width: 80%;
        margin: 0 auto;
    }
    .popular-service-img {
        height: 380px;
    }
    .main-header{
        flex-direction: column-reverse;
        gap: 60px;
    }
    .clinic-description {
        width: 80%;
        padding: 0;
        margin: 0 auto;
    }

    .clinic-header-picture{
        width: 80%;
        margin: 0 auto;
    }

    .picture-container{
        margin: 0 auto;
    }
    
    .popular-services h2, .popular-capelnisya h2 {
        font-size: 27px;
    }
    .header-title {
        font-size: 33px;
    }

    .service-3d-img{
        width: 300px;
        height: 280px;
    }
    .popular-capelnisya-img {
        height: 250px;
    }
}

@media (max-width:768px) {
    .clinic-description p {
        font-size: 16px;
        margin-bottom: 28px;
    }
    
    .clinic-header-picture {
        width: 75%;
    }
    .name-medicine {
        font-size: 14px;
        right: -11rem;
    }

    .name-medicine img {
        width: 30px;
        height: 30px;
    }
    .popular-services h2, .popular-capelnisya h2 {
        font-size: 26px;
    }
    .service-3d-img {
        width: 240px;
        height: 240px;
    }
    .popular-capelnisya-img {
        height: 230px;
    }
}

@media (max-width: 576px) { 
    .popular-service-content h3 {
        font-size: 18px;
    }
    .popular-service-content p {
        font-size: 14.5px;
    }
    .popular-service-btn {
        font-size: 14px;
        padding: 8px 15px;
    }
    .show-services-btn .btn-services {
        padding: 14px;
        font-size: 14px;
    }
    .main{
        padding-left: 5%;
        padding-right: 5%;
    }

    .popular-services-item {
        width: 90%;
    }

    .header-title {
        font-size: 28px;
        letter-spacing: 0.15em;
    }
    .clinic-description {
        width: 90%;
    }

    .clinic-description p {
        font-size: 14.5px;
        margin-bottom: 28px;
    }
    
    .clinic-header-picture {
        width: 90%;
    }
    .name-medicine {
        font-size: 13px;
        padding: 16px;
        top: 108%;
        bottom: 0;
        left: -2rem;
        right: -2rem;
        justify-content: center;
    }

    .name-medicine img {
        width: 25px;
        height: 25px;
    }

    .header-btns{
        flex-direction: column;
    }

    .header-btn{
        width: fit-content;
        padding:14px 18px;
    }

    .header-title {
        white-space: nowrap;
    }
    .popular-services h2, .popular-capelnisya h2 {
        font-size: 21.5px;
    }
    .service-3d-img {
        width: 180px;
        height: 180px;
    }
    .popular-capelnisya-img {
        height: 180px;
    }

}

@media (max-width: 460px) { 
    .name-medicine {
        padding: 18px;
    }
    .header-title{
        font-size: 25px;
    }
}